<template>
  <div class="tires">
    <el-table
      :lazy="true"
      :data="tiresList"
      style="width: 100%">
      <el-table-column
        label="ID"
        width="50"
        prop="id" />
      <el-table-column
        label="Символ Скорости"
        prop="tire_param_speed_symbol_id" />
      <el-table-column
        label="Индекс нагрузки"
        prop="tire_param_load_index_id" />
      <el-table-column
        label="Ширины профиля"
        prop="the_param_width_id" />
      <el-table-column
        label="Высота профиля, %"
        prop="tire_param_height_id" />
      <el-table-column
        label="Посадочный диаметр, дюйм"
        prop="tire_param_height_id" />
      <el-table-column
        label="Каркас шины"
        prop="tire_param_height_id" />
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-link
            :href="createLink(scope.row.id)"
            type="primary"
            :underline="false">
            <el-button type="primary">
              Подробнее
              <i class="el-icon-arrow-right" />
            </el-button>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import { hasString, isBoolean } from '@/utils';
import dataTable from './data';

export default {
  name: 'Tires',
  data() {
    return {
      tiresList: dataTable,
    };
  },
  methods: {
    hasString,
    isBoolean,
    createLink(id) {
      const tireModelId = this.$route.params.tire_model_id;
      return `/tire-models/${tireModelId}/tires/${id}`;
    },
  },
};
</script>
