export default [
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
  {
    id: '232',
    tire_model_id: '78965',
    bx_id: '2323',
    tire_param_speed_symbol_id: 'G 90',
    tire_param_load_index_id: '46 170',
    the_param_width_id: '100',
    tire_param_height_id: '35',
    tire_param_diameter_id: 19,
    tire_param_carcass_id: 'Радиальный',
  },
];
